import React, { FC, useContext, useEffect, useState } from 'react'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { PageTitle } from '@components/configs/PageTitle'
import SectionTitle from '@components/ui/sectionTitle'
import * as pageUtils from '@pages/articles/__index.utils'
import { Article } from '@services/models/articles.model'
import SearchArticles from '@components/ui/articles/search'
import ArticleCard from '@components/ui/articles/card'
import Loader from '@components/ui/loader'
import ReactPaginate from '@components/ui/pagination'
import { joinClasses } from '@utils/styles'
import { formatStrapiText } from '@utils/methods'
import { useAppDispatch } from '@services/store'
import { checkIfIsPageArticles } from '@services/store/articles'

const ArticlesPage: FC = () => {
	const { pageData } = useContext<AppContextProps>(AppStateContext)
	const dispatch = useAppDispatch()

	const [articles, setArticles] = useState<Article[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const Items = ({ currentItems }) => {
		return (
			<div className="items">
				{!!currentItems &&
					currentItems.map((article: Article, key: number) => (
						<ArticleCard article={article} index={key} />
					))}
			</div>
		)
	}

	useEffect(() => {
		dispatch(checkIfIsPageArticles(true))

		return () => {
			dispatch(checkIfIsPageArticles(false))
		}
	}, [])

	const PaginatedItems = ({ itemsPerPage }) => {
		// We start with an empty list of items.
		const [currentItems, setProcessingItems] = useState<any>(null)
		const [pageCount, setPageCount] = useState(0)
		// Here we use item offsets; we could also use page offsets
		// following the API or data you're working with.
		const [itemOffset, setItemOffset] = useState(0)

		useEffect(() => {
			// Fetch items from another resources.
			const endOffset = itemOffset + itemsPerPage

			setProcessingItems(articles.slice(itemOffset, endOffset))
			setPageCount(Math.ceil(articles.length / itemsPerPage))
		}, [itemOffset, itemsPerPage, articles.length])

		// Invoke when user clicks to request another page.
		const handlePageClick = (event) => {
			const newOffset = (event.selected * itemsPerPage) % articles.length

			setItemOffset(newOffset)
		}

		return (
			<>
				<div className={pageUtils.classes.resultShowTotalAndCount}>
					{itemOffset + 1}
					{` ${pageData?.assets?.articles_to} `}
					{itemOffset + itemsPerPage > articles.length
						? articles.length
						: itemOffset + itemsPerPage}
					{` ${pageData?.assets?.articles_of} ${articles.length} ${pageData?.assets?.articles_results}`}
				</div>
				<Items currentItems={currentItems} />
				<ReactPaginate
					hide={articles.length <= itemsPerPage}
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					marginPagesDisplayed={2}
					pageCount={pageCount}
					previousLabel="&nbsp;"
					nextLabel="&nbsp;"
					pageClassName={pageUtils.classes.pager}
					pageLinkClassName={pageUtils.classes.pager}
					previousClassName={pageUtils.classes.pager}
					previousLinkClassName={joinClasses([
						pageUtils.classes.pager,
						pageUtils.classes.simple,
						pageUtils.classes.previous
					])}
					nextClassName={pageUtils.classes.pager}
					nextLinkClassName={joinClasses([
						pageUtils.classes.pager,
						pageUtils.classes.simple
					])}
					breakLabel="..."
					breakClassName={pageUtils.classes.pager}
					breakLinkClassName={pageUtils.classes.pager}
					containerClassName={pageUtils.classes.pagination}
					activeClassName={pageUtils.classes.currentPage}
					firstItemLabel="&nbsp;"
					lastItemLabel="&nbsp;"
					firstItemClassName={pageUtils.classes.pager}
					lastItemClassName={pageUtils.classes.pager}
					firstItemLinkClassName={joinClasses([
						pageUtils.classes.pager,
						pageUtils.classes.double,
						pageUtils.classes.previous
					])}
					lastItemLinkClassName={joinClasses([
						pageUtils.classes.pager,
						pageUtils.classes.double
					])}
					disabledClassName={pageUtils.classes.hidde}
				/>
			</>
		)
	}

	return (
		<>
			<PageTitle title={pageData.title} />
			<SectionTitle title={pageData.title} />

			<section className={pageUtils.classes.section}>
				<SearchArticles
					isLoading={isLoading}
					onSetArticles={setArticles}
					onSetIsLoading={setIsLoading}
				/>
			</section>

			<section className={pageUtils.classes.section}>
				{isLoading && <Loader text={pageData?.assets?.loading} />}
				{!isLoading && articles.length === 0 && (
					<>{formatStrapiText(pageData?.assets?.articles_notFound)}</>
				)}
				{!isLoading && articles.length > 0 && (
					<>
						<PaginatedItems itemsPerPage={10} />
					</>
				)}
			</section>
		</>
	)
}

export default ArticlesPage

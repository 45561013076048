import Breakpoints from '@utils/breakpoints'
import { ButtonStyle, Colors, Indexes } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { Article } from '@services/models/articles.model'
import config from '@utils/config'

export type SearchProps = {
	isLoading: boolean
	isHeader?: boolean
	showClearTextIcon?: boolean
	onSetArticles: (articles: Article[]) => void
	onSetIsLoading: (loading: boolean) => void
}

type SearchClassType = {
	form: string
	formHeader: string
	input: string
	btnActions: string
	submitBtn: string
	clearBtn: string
}

export const searchClasses: SearchClassType = makeClasses({
	form: {
		display: 'flex',
		alignItems: 'center',
		img: {
			width: '25px',
			[Breakpoints.maxWidth('md')]: {
				width: '20px'
			},
			[Breakpoints.maxWidth('sm')]: {
				width: '18px'
			}
		}
	},
	formHeader: {
		position: 'relative',
		input: {
			height: '80px'
		}
	},
	submitBtn: {
		...ButtonStyle,
		background: Colors.secondary,
		borderColor: Colors.secondary,
		borderRadius: '50%',
		padding: '15px',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	input: {
		fontSize: '20px',
		flex: 1,
		height: '60px',
		border: 'none',
		borderBottom: `1px solid ${Colors.greyAlto}`,
		'&:focus': {
			border: 0,
			borderBottom: `1px solid ${Colors.greyAlto}`
		},
		[Breakpoints.maxWidth('md')]: {
			fontSize: '18px'
		},
		[Breakpoints.maxWidth('xs')]: {
			fontSize: '16px'
		},
		'&::placeholder': {
			fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
			fontSize: '20px',
			color: Colors.darkGrey,
			[Breakpoints.maxWidth('md')]: {
				fontSize: '18px'
			},
			[Breakpoints.maxWidth('xs')]: {
				fontSize: '12px'
			}
		}
	},
	clearBtn: {
		...ButtonStyle,
		background: Colors.darkWhite,
		borderColor: Colors.darkWhite,
		borderRadius: '50%',
		padding: '6px',
		marginRight: '30px',
		cursor: 'pointer',
		img: {
			width: '20px'
		},
		'&:hover': {
			background: Colors.greyAlto,
			borderColor: Colors.greyAlto
		},
		[Breakpoints.maxWidth('sm')]: {
			marginRight: '15px'
		}
	},
	btnActions: {
		display: 'flex',
		alignItems: 'center',
		position: 'absolute',
		height: '100%',
		top: 0,
		right: 0
	}
})

export type ArticleCardProps = {
	article: Article
	index: number
}

type ArticleCardClassType = {
	root: string
	title: string
	content: string
}

export const articleCardClasses: ArticleCardClassType = makeClasses({
	root: {
		padding: '20px 0',
		marginBottom: '10px',
		borderBottom: `1px solid ${Colors.greyAlto}`,
		'&:hover': {
			cursor: 'pointer',
			h3: {
				textDecoration: 'underline'
			}
		}
	},
	title: {
		margin: 0,
		fontSize: '20px',
		fontWeight: 400,
		color: Colors.secondary
	},
	content: {
		marginTop: '10px',
		fontSize: '16px',
		fontWeight: 400,
		lineHeight: '25px',
		color: Colors.lightBlack
	}
})

type SearchBoxClassType = {
	root: string
	searchBoxBtn: string
	searchBoxBtnOpen: string
	searchContainer: string
	display: string
	hide: string
	section: string
	btnSeeAllResults: string
	sectionMobile: string
	sectionBottom: string
}

export const searchBoxClasses: SearchBoxClassType = makeClasses({
	root: {
		position: 'relative',
		height: '100%'
	},
	searchBoxBtn: {
		...ButtonStyle,
		alignItems: 'center',
		border: 0,
		borderRadius: 0,
		height: '100%',
		padding: '0 20px',
		fontSize: '14px',
		transition: '0.5s',
		lineHeight: '60px',
		cursor: 'pointer',
		color: Colors.darkGrey,
		img: {
			width: '20px',
			marginRight: '10px'
		},
		[Breakpoints.maxWidth('laptop')]: {
			background: Colors.grey,
			marginRight: '15px',
			span: {
				display: 'none'
			},
			img: {
				marginRight: 0
			}
		},
		'&:hover': {
			background: Colors.greyAlto
		}
	},
	searchBoxBtnOpen: {
		background: Colors.secondary,
		color: Colors.white,
		padding: '0 25px',
		img: {
			marginRight: '0'
		},
		'&:before': {
			content: '""',
			position: 'absolute',
			background: Colors.blackTransparent50,
			top: '100%',
			left: `-${config.sidebar.sidebarWidth}`,
			width: '100vw',
			height: '200vh',
			[Breakpoints.minWidth('xxl')]: {
				left: `-${config.sidebar.sidebarWidthXXL}`
			},
			[Breakpoints.maxWidth('laptop')]: {
				left: 0
			}
		}
	},
	searchContainer: {
		background: Colors.white,
		position: 'absolute',
		top: '100%',
		left: `-${config.sidebar.sidebarWidth}`,
		width: '100vw',
		[Breakpoints.minWidth('xxl')]: {
			left: `-${config.sidebar.sidebarWidthXXL}`
		},
		[Breakpoints.maxWidth('laptop')]: {
			left: 0
		}
	},
	display: {
		display: 'block'
	},
	hide: {
		display: 'none'
	},
	section: {
		margin: '0 auto',
		width: '100%',
		maxWidth: `${Breakpoints.values.laptop}px`,
		padding: '30px',
		[Breakpoints.maxWidth('md')]: {
			padding: '30px 20px 15px'
		},
		[Breakpoints.minWidth('sidebar')]: {
			maxWidth: `${Breakpoints.values.laptop}px`
		},
		[Breakpoints.minWidth('sidebarlg')]: {
			maxWidth: `${Breakpoints.values.sidebar}px`
		},
		[Breakpoints.minWidth('sidebarxl')]: {
			maxWidth: `${Breakpoints.values.sidebarxl}px`
		}
	},
	btnSeeAllResults: {
		...ButtonStyle,
		marginTop: '30px',
		borderColor: Colors.secondary,
		background: Colors.secondary,
		color: Colors.white,
		padding: '15px 30px',
		fontSize: '16px',
		'&:hover': {
			cursor: 'pointer',
			background: Colors.darkBlue2,
			borderColor: Colors.darkBlue2
		}
	},
	sectionMobile: {
		overflow: 'auto',
		maxHeight: 'calc(100vh - 200px)',
		[Breakpoints.maxWidth('md')]: {
			padding: '15px 20px 30px'
		},
		[Breakpoints.maxWidth('sm')]: {
			maxHeight: 'calc(100vh - 185px)'
		}
	},
	sectionBottom: {
		[Breakpoints.maxWidth('md')]: {
			paddingBottom: '30px'
		}
	}
})

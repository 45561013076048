import React, {
	FC,
	FormEvent,
	RefObject,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react'
import { Icon } from '@components/ui/Icon'
import { closeGreyIcon, magnifierWhiteIcon } from '@images/icons'
import { Article } from '@services/models/articles.model'
import * as pageUtils from '@components/ui/articles/__articles.utils'
import {
	getIsSearchHeaderOpen,
	getSearchResults,
	getSearchText
} from '@services/store/articles/selector'
import { useAppDispatch, useAppSelector } from '@services/store'
import { fireSearch } from '@services/store/articles'
import { formatStrapiText } from '@utils/methods'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { Collapse } from 'react-collapse'
import { joinClasses } from '@utils/styles'

const SearchArticles: FC<pageUtils.SearchProps> = ({
	isLoading,
	isHeader,
	showClearTextIcon,
	onSetArticles,
	onSetIsLoading
}) => {
	const { pageData } = useContext<AppContextProps>(AppStateContext)
	const dispatch = useAppDispatch()

	const searchTextStore: string = useAppSelector(getSearchText)
	const searchResults: Article[] = useAppSelector(getSearchResults)
	const isSearchBoxOpen: boolean = useAppSelector(getIsSearchHeaderOpen)

	const inputRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null)
	const [searchText, setSearchText] = useState<string>('')

	const onSubmitSearchArticles = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		onSetIsLoading(true)

		if (searchText === '') {
			clearSearchText()

			return onSetIsLoading(false)
		}
		try {
			const articles: Article[] =
				searchText === searchTextStore
					? searchResults
					: await Article.searchArticlesByKeyword(searchText)

			onSetArticles(articles)

			dispatch(
				fireSearch({
					searchText,
					searchResults: articles
				})
			)
		} catch (e) {
			dispatch(
				fireSearch({
					searchText
				})
			)
			onSetIsLoading(false)
		}

		setTimeout(() => {
			onSetIsLoading(false)
		}, 50)
	}

	const clearSearchText = () => {
		setSearchText('')
		onSetArticles([])

		dispatch(
			fireSearch({
				searchText: '',
				searchResults: []
			})
		)
	}

	const onClearSearchText = (e: FormEvent<HTMLButtonElement>) => {
		e.preventDefault()
		clearSearchText()
	}

	const onChangeSearchText = (e: FormEvent<HTMLInputElement>) =>
		setSearchText(e.currentTarget.value)

	const triggerSearchBoxToggleAction = () => {
		if (isSearchBoxOpen) {
			return inputRef.current?.focus()
		}

		return inputRef.current?.blur()
	}

	useEffect(() => {
		if (searchText === '' && searchTextStore !== '') {
			setSearchText(searchTextStore)
		}
	}, [searchTextStore])

	useEffect(() => {
		if (
			searchText === '' &&
			searchTextStore !== '' &&
			searchResults.length > 0
		) {
			onSetArticles(searchResults)
		}
	}, [searchResults])

	useEffect(() => {
		if (inputRef !== null) {
			triggerSearchBoxToggleAction()
		}
	}, [isSearchBoxOpen])

	return (
		<form
			className={joinClasses([
				pageUtils.searchClasses.form,
				isHeader ? pageUtils.searchClasses.formHeader : ''
			])}
			onSubmit={onSubmitSearchArticles}
		>
			<input
				className={pageUtils.searchClasses.input}
				type="text"
				placeholder={formatStrapiText(pageData?.assets?.header_inputSearch)}
				value={searchText}
				onChange={onChangeSearchText}
				disabled={isLoading}
				ref={inputRef}
			/>
			<div className={isHeader ? pageUtils.searchClasses.btnActions : ''}>
				<Collapse isOpened={!!showClearTextIcon && searchText !== ''}>
					<button
						onClick={onClearSearchText}
						type="button"
						className={pageUtils.searchClasses.clearBtn}
					>
						<Icon src={closeGreyIcon} />
					</button>
				</Collapse>
				<button type="submit" className={pageUtils.searchClasses.submitBtn}>
					<Icon src={magnifierWhiteIcon} />
				</button>
			</div>
		</form>
	)
}

export default SearchArticles

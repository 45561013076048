import React, { FC, MouseEvent, useEffect } from 'react'
import * as pageUtils from '@components/ui/articles/__articles.utils'
import { navigate } from '@components/ui/link'
import { useAppDispatch, useAppSelector } from '@services/store'
import { getIsSearchHeaderOpen } from '@services/store/articles/selector'
import { doOpenSearchHeader } from '@services/store/articles'

const ArticleCard: FC<pageUtils.ArticleCardProps> = ({ article, index }) => {
	const dispatch = useAppDispatch()
	const isSearchHeaderOpen: boolean = useAppSelector(getIsSearchHeaderOpen)

	const onNavigateToArticle = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault()

		if (isSearchHeaderOpen) {
			dispatch(doOpenSearchHeader(false))
		}

		navigate(article.link, {
			state: {
				articleState: article
			}
		})
	}

	const addLinkAttribute = () => {
		document
			.querySelector(`.${pageUtils.articleCardClasses.content}`)
			?.querySelectorAll('a')
			.forEach((link) => {
				if (!link.href.includes('#')) {
					link.setAttribute('target', '_blank')
				}
			})
	}

	useEffect(() => {
		addLinkAttribute()
	}, [])

	return (
		<div
			className={pageUtils.articleCardClasses.root}
			onClick={onNavigateToArticle}
			key={index}
		>
			<h3 className={pageUtils.articleCardClasses.title}>{article.title}</h3>
			<div className={pageUtils.articleCardClasses.content}>
				{article.description}
			</div>
		</div>
	)
}

export default ArticleCard
